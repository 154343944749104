.App {
  text-align: center;
  font-family: 'Playfair Display', serif;
}

.main-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  background-color: white;
  margin-top: 85px;
  height: 100vh;
}

/* MEDIA QUERIES */

@media only screen and (min-width:600px) {
  .main-container {
    display: flex;
    flex-direction: row;
    margin-top: 85px;
  }
}


.Footer-container {
  background-color: white;
  border-top: 1px solid lightblue;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.Footer-container p {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}
.Header-container {
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100px;
  border-bottom: 1px solid lightblue;
}

.Header-container h1 {
  font-size: 70px;
  font-family: 'Fredericka the Great', cursive;
  margin-top: 10px;
}

a {
  text-decoration: none;
  color: black;
}
.AdoptionPage-container {
  background-color: white;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AdoptionPage-container h2 {
  font-size: 30px;
}

.AdoptionPage-container h4, p, li, img, ul {
  margin-top: 3px;
  margin-bottom: 5px;
}

.AdoptionPage-container button {
  background-color: rgb(72, 192, 232);
  color: white;
  padding: 3px 10px;
  border-radius: 10px;
  box-shadow: 3px 3px 8px 0px rgba(179, 179, 179, 1);
  font-size: 18px;
  margin-bottom: 10px;
}

.AdoptionPage-container button:hover {
  background-color: lightcoral;
}

.AdoptionPage-container button:disabled {
  background-color: lightgray;
}

.AdoptionPage-container img {
  width: 200px;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 20px;
  
}

.AdoptionPage-image-list-container, .AdoptionPage-container {
  display: flex;
  flex-direction: column;
}

.AdoptionPage-container {
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  justify-content: space-evenly;
}

.AdoptionPage-pet-info {
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 600px) {
  .AdoptionPage-container {
    min-width: 60vw;
    flex-direction: row;
  }

  .AdoptionPage-container section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .AdoptionPage-image-list-container, .AdoptionPage-container {
    display: flex;
    flex-direction: row;
  }
}
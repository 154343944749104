.RightSidebar-container {
  /* display: none; */
  background-color: lightblue;
  margin-bottom: 15px;
  
}

.RightSidebar-container h2 {
  font-size: 20px;
  font-family: 'Fredericka the Great', cursive;
}

.RightSidebar-container ul {
  list-style: none;
}

.adopted-pets {
  padding-left: 0;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 600px) {
  .RightSidebar-container {
    width: 20%;
  }
}
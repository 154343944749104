.LandingPage-container {
  background-color: white;
  padding-bottom: 80px;
  max-width: 80%;
  margin: 0 auto;
}

.LandingPage-container h2 {
  font-size: 30px;
  margin-bottom: 10px;
}

.LandingPage-container p {
  max-width: 80%;
  padding-right: 10%;
  padding-left: 10%;
}

.LandingPage-container section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LandingPage-container button {
  background-color: rgb(72, 192, 232);
  color: white;
  padding: 3px 10px;
  border-radius: 10px;
  box-shadow: 3px 3px 8px 0px rgba(179, 179, 179, 1);
  font-size: 18px;
}

.LandingPage-container button:hover {
  background-color: lightcoral;
}

.LandingPage-container img {
  width: 150px;
  border-radius: 10px;
  margin-bottom: 20px;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 600px) {
  .LandingPage-container {
    min-width: 60%;
  }
}
.LeftSidebar-container {
  background-color: lightblue;
  text-align: center;
}

.LeftSidebar-container h2{
font-size: 20px;
font-family: 'Fredericka the Great', cursive;
}

.users {
  list-style: none;
  padding-left: 0;
}

li {
  margin-bottom: 15px;
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .LeftSidebar-container {
    width: 20%;
    margin-bottom: 20px;
  }
}
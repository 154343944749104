body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @media only screen and (min-width: 1200px) {
  body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
} */
